.courseCard {
  width: calc(20% - 2rem);
  min-width: 20vw;
  margin: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  border: 1px solid #5c5b41;
}


.courseCard a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.courseCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 10px #cac895;
}

.click {
  cursor: pointer;
}

.no-decoration {
  text-decoration: none;
}

.courseCard-header {
  position: relative;

}

.courseCard-img {
  object-fit: cover;
}

.courseCard-body {
  flex: 1;
  /* Expand the body to fill the remaining space */
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid #ccc; Add a gray bottom border */
}

.courseCard-title {
  font-size: 1.5rem;
  margin: 0.2rem;

}

.courseCard-text {
  font-size: 1rem;
  flex: 1;
  /* Expand the text to fill the remaining space */
  color: #b9b9b9;
}

.courseCard-footer {
  margin: 0.5rem;
}

.courseCard-subtitle-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courseCard-subtitle {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  /* bold */
  font-weight: 700;
}

.icon-inline {
  margin-right: 0.25rem;
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .courseCard {
    width: calc(20% - 2rem);
    margin: 0.5rem;
  }
}

@media (max-width: 768px) {
  .courseCard {
    width: 100%;
    margin: 1rem;
  }
}

@media (max-width: 480px) {
  .courseCard {
    width: 100%;
    margin: 0.5rem;
  }
}


.authorRow {
  display: flex;
  align-items: center;
  overflow: hidden;

}



.courseCard-author-title,
.courseCard-author-person {
  margin: 0.1rem;
}

.courseCard-author-person {
  font-size: 1.3rem;
  font-weight: 400;
}