/* append to bottom */
.bottom-append {
    font-size: 1.5rem;
    position: relative;
    width: 100%;
    margin-top: 3rem;
    text-align: center;
}


.footer {
    tab-size: 4;
    font-size: 13px;
    line-height: inherit;
    width: 100%;
    margin-top: 1.5rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1536px;
    padding-left: 2rem;
    padding-right: 2rem;
    color: rgb(178 190 205);
}


.footer a {
    text-decoration: none;
    cursor: pointer;
    color:inherit;
  }