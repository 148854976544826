@font-face {
  font-family: 'Cubano';
  src: url('./assets/fonts/Cubanito/Cubano.ttf') format('truetype');
}

@font-face {
  font-family: 'sofia-pro';
  src: url('./assets/fonts/Sofia/sofiapro-light.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'sofia-pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --theme-orange: orange;
  --theme-orange-light: #74c454;
  --theme-green: #74c454;
}

.green {
  color: var(--theme-green)
}

.orange {
  color: var(--theme-orange)
}

html {
  font-family: 'sofia-pro'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
