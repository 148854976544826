
.funk-font{
  font-family: 'Cubano';
  text-shadow: 0 0 5px #d6a800;
}

/* create root color variables */
.App {
  background-color: #17191C;
  color: white;
  min-height: 100vh;
}
.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mx-auto {
  margin-left: auto;
  margin-right: auto;
}


.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px;
}


.center-flex {
  display: flex;
    justify-content: center;
}

a {
  text-decoration: none;
  cursor: pointer;
  color:inherit;
}

.col {
  flex: 1 0 0%;
}