
/* Markdown Styles */

/* Headers */
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.markdown h1 {
  font-size: 28px;
  /* border-bottom: 1px solid #ffffff38; */
}
.markdown .border {
  border-bottom: 1px solid #ffffff38;
}
.markdown h2 {
  font-size: 24px;
}

.markdown h3 {
  font-size: 20px;
}

.markdown h4 {
  font-size: 18px;
}

.markdown h5 {
  font-size: 16px;
}

.markdown h6 {
  font-size: 14px;
}

/* Paragraphs */
.markdown p {
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: #fdfdfd;
}

/* Links */
.markdown a {
  color: #9ab5d4;
  text-decoration: none;
}

/* Lists */
.markdown ul,
.markdown ol {
  padding-left: 2em;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.markdown li {
  margin-bottom: 0.5em;
}

/* Code Blocks */
.markdown pre {
  background-color: #f6f8fa;
  padding: 20px;
  border-radius: 1rem;
  overflow: auto;
}


/* Blockquotes */
.markdown blockquote {
  margin-top: 0;
  margin-bottom: 1em;
  background-color: #0062d1;
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
}

/* Horizontal Rule */
.markdown hr {
  border: none;
  border-top: 1px solid #eaecef;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Images */
.markdown img {
  max-width: 100%;
  height: auto;
}

/* Tables */
.markdown table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.markdown th,
.markdown td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown th {
  background-color: #f6f8fa;
  font-weight: bold;
}

/* Miscellaneous */
.markdown strong,
.markdown b {
  font-weight: bold;
}

.markdown em,
.markdown i {
  font-style: italic;
}

.markdown hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 1em;
  margin-bottom: 1em;
}

