.customIcon {
    width: 30px;
    height: 30px;
    /* if svg, change line color to white */
    fill: white;
    background-color: var(--theme-orange);
    border-radius: 0.2rem;
}

.customIcon:hover {
    background-color: var(--theme-orange-light);
    cursor: pointer;
    /* zoom */
    transform: scale(1.1);
    overflow: hidden;
}

/* */

.svgyoutube {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    fill: var(--theme-orange);
  }
  