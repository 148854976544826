.button {
  background-color: var(--theme-orange);
  color: #000000;
  border: none;
  padding: 0.2rem 0.7rem;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  min-width: 20px;
  min-height: 2.5rem;
  margin: 0.2rem;
  font-family: 'sofia-pro';
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* upper case */
  text-transform: uppercase;
  /* bold */
  font-weight: bold;
}

.button:hover {
  background-color: var(--theme-orange-light);
  scale: 1.1;
}

.button img {
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
}