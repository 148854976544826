.alert {
  padding: 1rem 1rem;
  margin: 10px;
  border: 0 solid transparent;
  border-radius: 0.33rem;
  border-left-width: 0.33rem;
}

.alert-warning {
  background: #f5005638;
  color: #f50057;
  border-color: #f50057;
}