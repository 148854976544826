.header {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  margin-left: 1rem;
}

.nav-list {
  list-style: none;
  display: flex;
  margin-left: 1rem;
  padding: 0;
}

.nav-item {
  margin: 20px;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.nav-link:hover,
.nav-link:focus {
  border-bottom: 1px solid #fff;
}

.auth-buttons {
  display: flex;
  /* push to left */
  margin-left: auto;
}


.sign-out-button {
  background-color: #dc3545;
}

.nav-menu-icon {
  display: none;
  cursor: pointer;
  color: #007bff;
}

.nav-list-mobile {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
  z-index: 1;
}

.nav-list-mobile.mobile-open {
  display: flex;
}

@media (max-width: 767px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-desktop {
    display: none;
  }

  .nav-menu-icon {
    display: block;
  }

  .nav-list-mobile {
    display: none;
  }

  .mobile-open {
    display: flex;
  }

  .nav-menu-icon:hover,
  .nav-menu-icon:focus {
    color: #007bff;
  }

  .nav-list-mobile {
    width: 100%;
  }

  .nav-list-mobile .nav-item {
    margin-bottom: 10px;
  }
}

.nav-list a {
  align-items: center;
}

.logo {
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0;
  padding: 0;
  animation: spin 1s linear infinite;
}

.logo:hover, .nav .nav-item:hover {
  scale: 1.3;
}
